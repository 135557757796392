<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :footer-left-actions="footerLeftActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/dc-goods-list"
    ></custom-table>
  </div>
</template>
  
  <script>
import { httpClient } from "@/libs/http";
import {
  GOODS_ISSUE_STATE,
  CUSTOM_VIEW_DC_ORDER_LIST,
  YES_NO_OPTIONS,
} from "@/libs/const";

export default {
  name: "Goods",
  components: {},
  data: () => ({
    customView: CUSTOM_VIEW_DC_ORDER_LIST,
    columns: [],
    footerLeftActions: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    filters: {},
    statusOptions: [...GOODS_ISSUE_STATE],
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  created() {
    const columns = [
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse"),
        placeholder: this.$t("labels.warehouse"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: false,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
        defaultValue: this.getUrlParameter("customer_goods_barcode")
          ? this.getUrlParameter("customer_goods_barcode")
          : "",
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        required: true,
        options: this.statusOptions,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: false,
        sortName: "sku",
        key: "sku",
        required: true,
        defaultValue: this.getUrlParameter("sku")
          ? this.getUrlParameter("sku")
          : "",
      },
      {
        type: "select-category",
        label: this.$t("labels.category"),
        placeholder: this.$t("labels.category"),
        name: "id_category",
        hasSort: false,
        sortName: "category_name",
        key: "category_name",
      },
      {
        type: "select-filter",
        label: this.$t("labels.image"),
        placeholder: this.$t("labels.image"),
        name: "image",
        hasSort: false,
        sortName: "url_images",
        key: "url_images",
        options: this.imageOptions,
        required: true,
        component: "Image",
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "description",
        hasSort: false,
        sortName: "description",
        key: "description",
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: false,
        sortName: "size",
        key: "size",
      },
      {
        type: "input-filter",
        label: this.$t("labels.unit"),
        placeholder: this.$t("labels.unit"),
        name: "unit",
        hasSort: false,
        sortName: "unit",
        key: "unit",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receiving_quantity"),
        placeholder: this.$t("labels.receiving_quantity"),
        name: "receiving",
        hasSort: true,
        sortName: "receiving",
        key: "receiving",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.wait_pickup_quantity"),
        placeholder: this.$t("labels.wait_pickup_quantity"),
        name: "wait_pickup",
        hasSort: true,
        sortName: "wait_pickup",
        key: "wait_pickup",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.available_quantity_1"),
        placeholder: this.$t("labels.available_quantity_1"),
        name: "available",
        hasSort: true,
        sortName: "available",
        key: "available",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerLeftActions = [];
    this.footerLeftActions = [...footerLeftActions];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [
      {
        text: this.$t("labels.sku"),
        key: "total_sku",
      },
      {
        text: this.$t("labels.available_quantity_1"),
        key: "total_available",
      },
      {
        text: this.$t("labels.receiving_quantity"),
        key: "total_receiving",
      },
      {
        text: this.$t("labels.wait_pickup_quantity"),
        key: "total_wait_pickup",
      },
    ];
    this.sums = [...sums];
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },

    async downloadExcel(filters) {
      if (window.exportWaiting) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      try {
        await httpClient.post("/dc-order-list-export", filters);
        window.exportWaiting = true;
        this.$vToastify.warning(this.$t("messages.loading"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async orderCancel(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_order"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/dc-order-cancel", {
          id: item.id,
        });
        this.isLoading = false;
        this.refreshCustomTable();
        this.$vToastify.warning(this.$t("messages.order_cancel_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

  